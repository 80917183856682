<template>
  <b-card>
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img
            ref="previewEl"
            rounded
            :src="optionsLocal.avatar"
            height="80"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group
            :label="appName + ' Address'"
            label-for="account-custody_address"
          >
            <a
              target="_blank"
              href="https://etherscan.io/address/0xda9dfa130df4de4673b89022ee50ff26f6ea73cf"
              >see address on blockchain</a
            >
            <b-form-input
              v-model="optionsLocal.custody_address"
              placeholder="0x..."
              name="custody_address"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Your Wallet Address" label-for="youraddress">
            <b-form-input
              v-model="optionsLocal.wallet_address"
              name="wallet_address"
              placeholder="your wallet address here"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="12" class="d-flex justify-content-start">
          <b-form-radio
            class="mr-2"
            v-model="Selected"
            name="custody_setting_radio"
            value="exchange-custody"
          >
            {{ appName }} Custody
          </b-form-radio>
          <b-form-radio
            v-model="Selected"
            name="custody_setting_radio"
            value="self-custody"
          >
            Self-Custody
          </b-form-radio>
        </b-col>

        <!-- buttons -->
        <b-col cols="12 mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
          >
            Save changes
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            class="mt-1"
          >
            Reset
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BFormRadio,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import { $themeConfig } from "@themeConfig";

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BFormRadio,
  },
  directives: {
    Ripple,
  },
  props: {},
  data() {
    return {
      // App Name
      appName: $themeConfig.app.appName,
      appLogoImage: $themeConfig.app.appLogoImage,
      optionsLocal: {
        avatar: "https://logowik.com/content/uploads/images/708_ethereum.jpg",
        name: "Ethereum",
        custody_address: "0xDA9dfA130Df4dE4673b89022EE50ff26f6EA73Cf",
        wallet_address: "",
      },
      Selected: "exchange-custody",
    };
  },
  methods: {
    resetForm() {},
  },
  setup() {
    return {};
  },
};
</script>
